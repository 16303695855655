<!-- =========================================================================================
    File Name: Profile.vue
    Description: Profile Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="vx-col w-full mb-2">
      <vs-row vs-type="flex" vs-justify="center" class="mb-2">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <h2 class="mb-1 text-primary">
            <strong>{{
              $t(resources.Upgrade.i18n) || resources.Upgrade.name
            }}</strong>
          </h2>
        </vs-col>
      </vs-row>
      <form-wizard
        ref="checkoutWizard"
        color="rgba(var(--vs-primary), 1)"
        :title="null"
        :subtitle="null"
        :hide-buttons="true"
      >
        <tab-content
          :title="$t(resources.Product.i18n) || resources.Product.name"
          v-if="!isView"
          icon="feather icon-shopping-bag"
          class="mb-5"
        >
          <div class="vx-row">
            <div class="vx-col lg:w-3/4 w-full">
              <vx-card class="mb-base">
                <h4
                  class="text-center text-dark font-bold mb-2"
                  v-if="!isVivelo"
                >
                  <feather-icon
                    icon="CalendarIcon"
                    svgClasses="h-8 w-8 mb-1 stroke-current text-danger"
                  />
                  <strong class="font-semibold text-danger">
                    {{
                      $t(resources.ExpirationDate.i18n) ||
                        resources.ExpirationDate.name
                    }}:
                  </strong>
                  {{ renewal_date }}
                </h4>
                <h5 class="text-center text-dark font-semibold mb-4">
                  {{ getMessage }}
                </h5>
                <div
                  v-if="
                    contactDto.contactType ==
                      this.database.contactType.ClientIta
                  "
                >
                  <div class="vx-col md:w-1/2 w-full mb-4 mt-4">
                    <ul class="centerx">
                      <li>
                        <vs-checkbox
                          class="font-semibold"
                          v-model="convertDistributor"
                          >{{
                            $t(resources.ConvertDistributor.i18n) ||
                              resources.ConvertDistributor.name
                          }}</vs-checkbox
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="vx-row mb-2">
                  <div class="vx-col w-full">
                    <vs-table :data="upgradeList" class="vx-col w-full">
                      <template slot="thead">
                        <vs-th>
                          {{
                            $t(resources.Product.i18n) || resources.Product.name
                          }}
                        </vs-th>
                        <vs-th>
                          {{ $t(resources.Price.i18n) || resources.Price.name }}
                        </vs-th>
                      </template>

                      <template slot-scope="{ data }">
                        <vs-tr :key="item" v-for="(tr, item) in data">
                          <vs-td :data="data[item].productName">
                            <ul class="leftx">
                              <li>
                                <vs-radio
                                  v-model="radioUpgradeId"
                                  :vs-value="data[item].productId"
                                  v-on:change="setProduct(data[item].productId)"
                                >
                                  {{ data[item].name }}&nbsp;
                                  <vs-icon
                                    icon-pack="feather"
                                    icon="icon-arrow-right"
                                    color="success"
                                    size="small"
                                  ></vs-icon
                                  >&nbsp;
                                  <strong>{{ data[item].productName }}</strong>
                                </vs-radio>
                              </li>
                            </ul>
                          </vs-td>
                          <vs-td :data="data[item].price"
                            >USD {{ data[item].price }}</vs-td
                          >
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </div>
                <vs-button
                  v-if="radioUpgradeId != ''"
                  class="mt-6 ml-auto flex"
                  @click="$refs.checkoutWizard.nextTab()"
                  >{{
                    $t(resources.Next.i18n) || resources.Next.name
                  }}</vs-button
                >
              </vx-card>
            </div>

            <div class="vx-col lg:w-1/4 w-full">
              <billing :data="billingData" :installment="1"></billing>
              <vs-row
                vs-type="flex"
                vs-justify="center"
                class="mb-base"
                v-if="enabledButton"
              >
                <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                  <vs-button
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-heart"
                    size="large"
                    class="mt-4"
                    @click="validate()"
                    >{{
                      $t(resources.Generate.i18n) || resources.Generate.name
                    }}</vs-button
                  >
                </vs-col>
              </vs-row>
            </div>
          </div>
        </tab-content>
        <tab-content
          :title="
            $t(resources.PaymentMethod.i18n) || resources.PaymentMethod.name
          "
          icon="feather icon-credit-card"
          class="mb-5"
        >
          <div class="vx-row">
            <div class="vx-col lg:w-3/4 w-full relative">
              <vx-card
                class="mb-2"
                :title="$t(resources.Payment.i18n) || resources.Payment.name"
              >
                <div class="vx-col md:w-1/2 w-full mb-2">
                  <label class="vs-input--label">{{
                    $t(resources.WayToPay.i18n) || resources.WayToPay.name
                  }}</label>
                  <v-select
                    v-model="wayToPayOptionId"
                    :clearable="true"
                    :options="wayToPayOption"
                    :reduce="data => data.wayToPayId"
                    label="name"
                    v-on:input="setWayToPay(`${wayToPayOptionId}`)"
                    :hint="`${wayToPayOptionId}`"
                  />
                </div>
              </vx-card>

              <vx-card
                class="mb-2"
                v-if="wayToPayOptionId == database.merchantType.creditCard"
              >
                <h4 class="mb-4">
                  {{
                    $t(resources.AddPayment.i18n) || resources.AddPayment.name
                  }}
                  -
                  {{
                    $t(resources.CreditCard.i18n) || resources.CreditCard.name
                  }}
                </h4>
                <div class="vx-row">
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.Type.i18n) || resources.Type.name
                      }}</label>
                      <v-select
                        v-model="creditcardPayment.creditCardTypeId"
                        :clearable="false"
                        :options="creditcardOption"
                        :reduce="data => data.cardId"
                        label="name"
                        v-on:input="
                          setInstallmentNumber(
                            `${creditcardPayment.creditCardTypeId}`
                          )
                        "
                        :hint="`${creditcardPayment.creditCardTypeId}`"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-credit-card"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.CreditCardNumber.i18n) ||
                            resources.CreditCardNumber.name
                        "
                        v-model="creditcardPayment.cardNumber"
                        v-validate="'required|credit_card'"
                        name="cardNumber"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('cardNumber')"
                        >{{ errors.first("cardNumber") }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="vx-col md:w-1/3 w-full mb-2"
                    v-if="
                      installmentNumberOption &&
                        installmentNumberOption.length > 0
                    "
                  >
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.InstallmentNumber.i18n) ||
                          resources.InstallmentNumber.name
                      }}</label>
                      <v-select
                        v-model="creditcardPayment.installmentNumber"
                        :clearable="false"
                        :options="installmentNumberOption"
                        :reduce="data => data.cardId"
                        label="name"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        type="password"
                        :maxlength="maxCvv"
                        :label="
                          $t(resources.SecurityCode.i18n) ||
                            resources.SecurityCode.name
                        "
                        v-model="creditcardPayment.cardSecurityCode"
                        name="SecurityCode"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('SecurityCode')"
                      >{{ errors.first("SecurityCode") }}</span
                    >
                  </div>
                  <div class="vx-col w-full md:w-1/3 mb-4">
                    <label>{{
                      $t(resources.ExpirationYear.i18n) ||
                        resources.ExpirationYear.name
                    }}</label>
                    <vs-select
                      icon-no-border
                      :placeholder="
                        $t(resources.ExpirationYear.i18n) ||
                          resources.ExpirationYear.name
                      "
                      v-model="expirationYear"
                      v-on:change="expirationYearChange($event)"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item"
                        :text="item"
                        v-for="(item, index) in expirationYearOption"
                      />
                    </vs-select>
                  </div>
                  <div class="vx-col w-full md:w-1/3 mb-4">
                    <label>{{
                      $t(resources.ExpirationMonth.i18n) ||
                        resources.ExpirationMonth.name
                    }}</label>
                    <vs-select
                      icon-no-border
                      :placeholder="
                        $t(resources.ExpirationMonth.i18n) ||
                          resources.ExpirationMonth.name
                      "
                      v-model="expirationMonth"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item"
                        :text="item"
                        v-for="(item, index) in expirationMonthOption"
                      />
                    </vs-select>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        size="small"
                        :label="
                          $t(resources.Names.i18n) || resources.Names.name
                        "
                        v-model="creditcardPayment.cardHolderName"
                        v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                        name="cardHolderName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('cardHolderName')"
                      >{{ errors.first("cardHolderName") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        size="small"
                        :label="
                          $t(resources.LastName.i18n) || resources.LastName.name
                        "
                        v-model="creditcardPayment.cardholderLastName"
                        v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                        name="cardholderLastName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('cardholderLastName')"
                      >{{ errors.first("cardholderLastName") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        :label="
                          $t(resources.ZipCode.i18n) || resources.ZipCode.name
                        "
                        v-model="creditcardPayment.zipCode"
                        v-validate="'required|max:6'"
                        name="zipCodeCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('zipCodeCard')"
                      >{{ errors.first("zipCodeCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-mail"
                        size="small"
                        type="email"
                        :label="
                          $t(resources.Email.i18n) || resources.Email.name
                        "
                        v-model="creditcardPayment.email"
                        v-validate="'required|max:100|email'"
                        name="emailCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('emailCard')"
                      >{{ errors.first("emailCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-map-pin"
                        icon-no-border
                        size="small"
                        :label="
                          $t(resources.Address.i18n) || resources.Address.name
                        "
                        v-model="creditcardPayment.address"
                        v-validate="{
                          required: true,
                          regex: /^([a-zA-Z0-9 ]+)$/
                        }"
                        name="addressCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('addressCard')"
                      >{{ errors.first("addressCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        icon-no-border
                        size="small"
                        :label="
                          $t(resources.DocumentNumber.i18n) ||
                            resources.DocumentNumber.name
                        "
                        v-model="creditcardPayment.documentNumber"
                        v-validate="'required|max:20|alpha_num'"
                        name="documentCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('documentCard')"
                      >{{ errors.first("documentCard") }}</span
                    >
                  </div>

                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="creditcardPayment.amount"
                        v-validate="'required|decimal:2|min_value:5'"
                        name="amountCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('amountCard')"
                      >{{ errors.first("amountCard") }}</span
                    >
                  </div>
                </div>
                <div class="vx-col mb-2 mt-5" style="text-align: right">
                  <vs-button
                    color="primary"
                    type="filled"
                    @click.prevent="validPayment()"
                  >
                    {{ $t(resources.Save.i18n) || resources.Save.name }}
                  </vs-button>
                </div>
              </vx-card>

              <vx-card
                class="mb-2"
                v-if="wayToPayOptionId == database.merchantType.EWallet"
              >
                <h4 class="mb-4">
                  {{
                    $t(resources.AddPayment.i18n) || resources.AddPayment.name
                  }}
                  - {{ $t(resources.EWallet.i18n) || resources.EWallet.name }}
                </h4>
                <div class="vx-row">
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.Select.i18n) || resources.Select.name
                    }}</label>
                    <v-select
                      v-model="merchantId"
                      :clearable="false"
                      :options="walletOption"
                      :reduce="data => data.walletId"
                      label="name"
                      v-on:input="setAccountWallet(`${merchantId}`)"
                      :hint="`${merchantId}`"
                    ></v-select>
                  </div>
                  <div
                    class="vx-col md:w-1/3 w-full mb-2"
                    v-if="merchantId == database.merchants.BlockPay"
                  >
                    <label class="vs-input--label">{{
                      $t(resources.AccountNumber.i18n) ||
                        resources.AccountNumber.name
                    }}</label>
                    <v-select
                      v-model="accountWalletId"
                      :clearable="false"
                      :options="accountWalletOption"
                      :reduce="x => x.id"
                      label="name"
                      :hint="`${accountWalletId}`"
                      name="accountWalletId"
                      v-validate="'required'"
                    ></v-select>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('accountWalletId')"
                      >{{ errors.first("accountWalletId") }}</span
                    >
                  </div>
                  <div
                    class="vx-col md:w-1/3 w-full mb-2"
                    v-if="merchantId == database.merchants.Payout"
                  >
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        type="number"
                        :label="
                          $t(resources.DistributorID.i18n) ||
                            resources.DistributorID.name
                        "
                        v-model="eWalletPayment.itaCodeWallet"
                        v-validate="'required'"
                        name="itaCodeWallet"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('itaCodeWallet')"
                      >{{ errors.first("itaCodeWallet") }}</span
                    >
                  </div>
                  <div
                    class="vx-col md:w-1/3 w-full mb-2"
                    v-if="merchantId != 0 && merchantId != ''"
                  >
                    <div class="vx-col w-full" v-if="merchantId == database.merchants.BlockPay">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        type="number"
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="eWalletPayment.amount"
                        v-validate="'required|decimal:2|min_value:0.1'"
                        name="amountCard"
                      />
                    </div>
                    <div class="vx-col w-full" v-else>
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        type="number"
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="eWalletPayment.amount"
                        v-validate="'required|decimal:2|min_value:5'"
                        name="amountCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('amountCard')"
                      >{{ errors.first("amountCard") }}</span
                    >
                  </div>
                  <div class="vx-col mb-2 mt-5" style="text-align: right">
                    <vs-button
                      color="primary"
                      type="filled"
                      @click.prevent="validPayment()"
                    >
                      {{ $t(resources.Save.i18n) || resources.Save.name }}
                    </vs-button>
                  </div>
                </div>
              </vx-card>

              <vx-card
                class="mb-2"
                v-if="wayToPayOptionId == database.merchantType.transfer"
              >
                <h4 class="mb-4">
                  {{
                    $t(resources.AddPayment.i18n) || resources.AddPayment.name
                  }}
                  - {{ $t(resources.Transfer.i18n) || resources.Transfer.name }}
                </h4>
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.BankName.i18n) || resources.BankName.name
                    }}</label>
                    <v-select
                      v-model="transferPayment.bankTypeId"
                      :clearable="false"
                      :options="bankType"
                      :reduce="data => data.id"
                      label="name"
                      v-on:input="setAccountNumber(transferPayment.bankTypeId)"
                      :hint="transferPayment.bankTypeId"
                    />
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.AccountNumber.i18n) ||
                        resources.AccountNumber.name
                    }}</label>
                    <v-select
                      v-model="transferPayment.bankAccountId"
                      :clearable="false"
                      :options="accountNumberType"
                      :reduce="data => data.id"
                      label="account"
                    />
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.DocumentNumber.i18n) ||
                            resources.DocumentNumber.name
                        "
                        v-model="transferPayment.documentId"
                        name="documentCard"
                        v-validate="'required|max:20|alpha_num'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('documentCard')"
                      >{{ errors.first("documentCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        :label="
                          $t(resources.ReferenceCode.i18n) ||
                            resources.ReferenceCode.name
                        "
                        v-model="transferPayment.referenceCode"
                        name="referenceCode"
                        v-validate="'required|max:20|alpha_num'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('referenceCode')"
                      >{{ errors.first("referenceCode") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="transferPayment.amount"
                        name="amountCard"
                        v-validate="'required|decimal:2|min_value:5'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('amountCard')"
                      >{{ errors.first("amountCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <label class="btn btn-primary" style="margin-right: 80px">{{
                      $t(resources.Attachment.i18n) || resources.Attachment.name
                    }}</label>
                    <vs-input
                      type="file"
                      class="w-full"
                      @change="onFileSelected"
                    />
                  </div>
                </div>
                <div class="vx-col mb-2 mt-5" style="text-align: right">
                  <vs-button
                    color="primary"
                    type="filled"
                    @click.prevent="validPayment()"
                  >
                    {{ $t(resources.Save.i18n) || resources.Save.name }}
                  </vs-button>
                </div>
              </vx-card>
              <vx-card
                :title="
                  $t(resources.PaymentsReceived.i18n) ||
                    resources.PaymentsReceived.name
                "
              >
                <vs-table pagination max-items="10" :data="paymentList">
                  <template slot="thead">
                    <vs-th>{{
                      $t(resources.WayToPay.i18n) || resources.WayToPay.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Description.i18n) ||
                        resources.Description.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Value.i18n) || resources.Value.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.TransactionNumber.i18n) ||
                        resources.TransactionNumber.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.CreationDate.i18n) ||
                        resources.CreationDate.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Status.i18n) || resources.Status.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Options.i18n) || resources.Options.name
                    }}</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td
                        v-if="
                          data[indextr].merchantTypeId ==
                            database.merchantType.transfer
                        "
                        :data="data[indextr].merchantType"
                      >
                        <vs-button
                          color="success"
                          type="line"
                          icon-pack="feather"
                          icon="icon-download-cloud"
                          @click="
                            openDocument(
                              `${urlVision}payments/purchases/${data[indextr].attachmentId}.${data[indextr].attachmentExtension}`
                            )
                          "
                          >{{ data[indextr].merchantType }}</vs-button
                        >
                      </vs-td>
                      <vs-td v-else :data="data[indextr].merchantType">{{
                        data[indextr].merchantType
                      }}</vs-td>
                      <vs-td :data="data[indextr].information">{{
                        data[indextr].information
                      }}</vs-td>
                      <vs-td :data="data[indextr].value">{{
                        data[indextr].value
                      }}</vs-td>
                      <vs-td :data="data[indextr].transactionNumber">{{
                        data[indextr].transactionNumber
                      }}</vs-td>
                      <vs-td :data="data[indextr].creationDate">{{
                        data[indextr].creationDate
                      }}</vs-td>
                      <vs-td
                        v-if="
                          data[indextr].merchantTypeId ==
                            database.merchantType.EWallet &&
                            data[indextr].stageId == status.payment.pending
                        "
                        :data="data[indextr].merchantType"
                      >
                        <vs-button
                          color="success"
                          type="line"
                          icon-pack="feather"
                          icon="icon-refresh-ccw"
                          @click="
                            paymentEwallet(
                              `${data[indextr].information}`,
                              `${data[indextr].referenceId}`
                            )
                          "
                          >{{ data[indextr].stageName }}</vs-button
                        >
                      </vs-td>
                      <vs-td v-else :data="data[indextr].stageName">{{
                        data[indextr].stageName
                      }}</vs-td>
                      <vs-td>
                        <vs-button
                          color="success"
                          type="line"
                          icon-pack="feather"
                          icon="icon-eye"
                          @click="
                            openResponseMerchant(
                              `${data[indextr].merchantResponse}`
                            )
                          "
                        ></vs-button>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vx-card>
            </div>

            <div class="vx-col lg:w-1/4 w-full">
              <billing :data="billingData" :installment="1"></billing>
              <vs-row
                vs-type="flex"
                vs-justify="center"
                class="mb-base"
                v-if="enabledButton"
              >
                <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                  <vs-button
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-heart"
                    size="large"
                    class="mt-4"
                    @click="validate()"
                    >{{
                      $t(resources.Generate.i18n) || resources.Generate.name
                    }}</vs-button
                  >
                </vs-col>
              </vs-row>
            </div>
          </div>
        </tab-content>
      </form-wizard>
      <vx-card
        :title="$t(resources.Management.i18n) || resources.Management.name"
        v-if="stageId != 0"
      >
        <h3 class="text-center text-primary mb-4">
          <strong
            >{{
              $t(resources.OrderNumber.i18n) || resources.OrderNumber.name
            }}:</strong
          >
          {{ orderNumber }}
        </h3>
        <h3 class="text-center text-success">
          <strong
            >{{ $t(resources.Stage.i18n) || resources.Stage.name }}:</strong
          >
          {{ stageName }}
        </h3>
        <vs-tabs>
          <vs-tab
            :label="$t(resources.Comments.i18n) || t(resources.Comments.name)"
            icon-pack="feather"
            icon="icon-message-circle"
          >
            <comments
              :purchaseId="`${purchaseId}`"
              :currentStage="stageId"
              :purchaseType="4"
            ></comments>
          </vs-tab>
          <vs-tab
            :label="$t(resources.Tracking.i18n) || t(resources.Tracking.name)"
            icon-pack="feather"
            icon="icon-list"
          >
            <tracking
              :purchaseId="`${purchaseId}`"
              :currentStage="stageId"
            ></tracking>
          </vs-tab>
        </vs-tabs>
      </vx-card>
    </div>
    <vs-popup
      :title="$t(resources.Upgrade.i18n) || resources.Upgrade.i18n"
      :active.sync="popup"
    >
      <h1 class="text-center text-primary font-bold mb-base">
        {{ $t(resources.Hello.i18n) || resources.Hello.i18n }}
        {{ contactDto.name }},
      </h1>
      <h4 class="text-center mb-base">
        {{ $t(resources.OrderGenerated.i18n) || resources.OrderGenerated.i18n }}
        <strong>{{ orderNumber }}</strong>
      </h4>
    </vs-popup>
    <vs-popup
      :title="$t(resources.Detail.i18n) || resources.Detail.i18n"
      :active.sync="popupResMer"
    >
      <h1 class="text-center text-primary font-bold mb-base">
        {{ $t(resources.Answer.i18n) || resources.Answer.i18n }}:
      </h1>
      <p class="text-center mb-base">{{ responseMerchant }}</p>
    </vs-popup>
  </div>
</template>

<script>
import axios from "axios";
import resources from "@/i18n/resources.js";
import database from "@/assets/resources/enums/database.json";
import other from "@/assets/resources/enums/other.json";
import status from "@/assets/resources/enums/status.json";
import vSelect from "vue-select";
import alerts from "@/i18n/alerts.js";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import billing from "@/views/purchases/billing.vue";
import { Validator } from "vee-validate";
import HelloSign from "hellosign-embedded";
import moment from "moment";
import tracking from "../purchases/tracking.vue";
import comments from "../purchases/comments.vue";

export default {
  components: {
    vSelect,
    FormWizard,
    TabContent,
    billing,
    Validator,
    moment,
    tracking,
    comments
  },
  data() {
    return {
      urlVision: process.env.VUE_APP_VISION,
      database: database,
      other: other,
      status: status,
      resources: resources,
      alerts: alerts,
      popup: false,
      popupHS: true,
      popupResMer: false,
      responseMerchant: false,
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      email: JSON.parse(localStorage.getItem("userInfo")).email,
      countryCode: "USA",
      wayToPayOption: [],
      wayToPayOptionId: 0,
      creditcardPayment: {},
      creditcardOption: [],
      installmentNumberType: [],
      bankType: [],
      installmentNumberOption: [],
      purchaseId: 0,
      expirationYearOption: [],
      expirationMonthOption: [],
      currentYear: 0,
      currentMonth: 0,
      expirationYear: 0,
      expirationMonth: 0,
      billingData: [],
      transferPayment: {},
      eWalletPayment: {},
      accountNumberType: [],
      maxCvv: 0,
      productPrice: {},
      products: [],
      membershipId: 0,
      renewal_date: JSON.parse(localStorage.getItem("userInfo")).renewalDate,
      upgradeList: [],
      radioUpgradeId: "",
      productId: "",
      contactDto: {},
      colorAlert: "warning",
      isVivelo: true,
      paymentList: [],
      productsPrice: [],
      isView: false,
      orderNumber: 0,
      stageId: 0,
      stageName: "",
      merchantId: "",
      walletOption: [],
      accountWalletId: "",
      accountWalletOption: [],
      convertDistributor: false,
      priceMembership: {}
    };
  },
  computed: {
    getMessage() {
      let diffYear = 0;
      let dateYear1 = moment(this.renewal_date)
        .add(-1, "y")
        .format("YYYY");
      let dateYear2 = moment(new Date()).format("YYYY");
      let month1 = moment(this.renewal_date).format("MM");
      let month2 = moment(new Date()).format("MM");

      if (parseInt(dateYear1) + 1 == dateYear2 && month1 >= month2) {
        diffYear = 0;
      } else if (parseInt(dateYear1) + 1 <= dateYear2 && month1 >= month2) {
        diffYear = dateYear2 - (parseInt(dateYear1) + 1);
      } else diffYear = dateYear2 - dateYear1;

      return this.$i18n.t("MsgPendingMembership").replace("{0}", diffYear);
    },

    enabledButton() {
      let totalPay = 0;
      let products = this.billingData.filter(
        x => x.orderType == this.other.billingOrder.product
      );

      let _wayToPayTotal = this.billingData.filter(
        x => x.orderType == this.other.billingOrder.wayToPay
      );

      for (let index = 0; index < products.length; index++) {
        totalPay += products[index].productValue;
      }

      if (_wayToPayTotal.length > 0) {
        let _payments = _wayToPayTotal.reduce(
          (sum, current) => sum + parseFloat(current.productValue),
          0
        );
        if (totalPay <= _payments && this.stageId != 26 && this.orderNumber != null && this.orderNumber == 0) {
          return true;
        }
        return false;
      } else return false;
    }
  },
  methods: {
    async getEmbedForm(purchaseId) {
      const client = new HelloSign({
        clientId: "9c73b145f9aec2c07ad370af943f1582"
      });

      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_HELLOSIGN}membership/upgradeEmbedded/${purchaseId}`,
        headers: {
          "content-type": "application/json"
        }
      }).then(result => {
        if (result.data.success) {
          client.open(result.data.url, {
            skipDomainVerification: true,
            timeout: 6000000
          });
        }
      });
    },

    async getUpgrade() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_UPGRADE}validate`,
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          iboCode: this.itaCode,
          purchaseId: this.purchaseId,
          endPeriod: JSON.parse(localStorage.getItem("periods"))[0],
          fields:
            "ORDER_NUMBER,PV_AMOUNT,PV_YEAR,PV_PERIOD,DST_COUNTRY_CODE,SHIP_TO_COUNTRY,SHIP_TO_NAME,ENTRY_DATE,TOT_INVCE_AMOUNT",
          language: this.$i18n.locale
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("loggedIn")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.upgradeList = result.data.data;
            this.isVivelo = result.data.isVivelo;

            if (!result.data.isVivelo)
              this.renewal_date = localStorage.getItem("renewalDate");
            else this.upgradeList = result.data.data.filter(x => x.price > 0);

            if (this.purchaseId != 0 && this.purchaseId != null)
              await this.getAutoship();
          } else {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotUpgrade"),
              accept: this.acceptDialog
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getById() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}purchase/getById/${this.purchaseId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          this.billingData = [];
          if (result.data.success && result.data.code != 204) {
            let response = result.data.data;
            this.productId = response.detail[0].productId;
            this.orderNumber = response.orderNumber;
            this.stageId = response.stageId;
            this.stageName = response.stageName;
            response.detail.forEach(element => {
              this.billingData.push({
                productId: element.productId,
                productName: element.productName,
                currencyCode: "USD",
                orderType: this.other.billingOrder.product,
                productValue: element.value * element.amount,
                amount: element.amount,
                productTax: 0,
                productCode: element.productCode,
                priceLevel: response.priceLevel,
                imageUrl: `https://cdn1.visiontravel.net/Images/products/VIVE001.png`
              });
            });

            this.paymentList = response.payment;
            this.paymentConfirmed = response.paymentsConfirmed;

            this.billingData.push({
              productId: 0,
              productName: this.$i18n.t("Payments"),
              currencyCode: "USD",
              orderType: this.other.billingOrder.wayToPay,
              productValue: response.totalPayments,
              productCode: response.productCode
            });
            await this.getWayToPay();
          } else {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: this.$i18n
                .t("MsgNotMonthlyPayment")
                .replace("{0}", this.renewal_date),
              accept: this.acceptDialog
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getAutoship() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OVAPI}order/getAutoship/${this.purchaseId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("loggedIn")}`
        }
      }).then(
        async result => {
          this.radioUpgradeId = result.data.fatherId;
          this.setProduct(result.data.fatherId);
          await this.getPayments();
          this.billingData.push({
            productId: 0,
            productName: this.$i18n.t("Payments"),
            currencyCode: "USD",
            orderType: this.other.billingOrder.wayToPay,
            productValue: result.data.totalPayment,
            productCode: this.productCode
          });
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getProductPrice() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/GetByCountry/${this.$i18n.locale.toUpperCase()}/${
          this.countryCode
        }/${this.database.products.PRM001}/${this.database.contactType.Ita}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.priceMembership = result.data[0];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async setProduct(productId) {
      this.billingData = [];
      let product = this.upgradeList.find(x => x.productId == productId);
      this.productId = product.autoshipId;
      let diffYear = 0;
      if (!this.isVivelo) {
        let dateYear1 = moment(this.renewal_date)
          .add(-1, "y")
          .format("YYYY");
        let dateYear2 = moment(new Date()).format("YYYY");
        let month1 = moment(this.renewal_date).format("MM");
        let month2 = moment(new Date()).format("MM");
        if (parseInt(dateYear1) + 1 == dateYear2 && month1 >= month2) {
          diffYear = 0;
        } else if (parseInt(dateYear1) + 1 <= dateYear2 && month1 >= month2) {
          diffYear = dateYear2 - (parseInt(dateYear1) + 1);
        } else diffYear = dateYear2 - dateYear1;

        this.billingData = [
          {
            productId: product.membershipId,
            productName: product.membershipName,
            currencyCode: "USD",
            orderType: this.other.billingOrder.product,
            productValue: product.membershipPrice * diffYear,
            amount: diffYear,
            productTax: 0,
            productCode: product.membershipCode,
            priceLevel: 1,
            imageUrl: `https://cdn1.visiontravel.net/Images/products/${product.productCode}.png`
          }
        ];
      }

      if (this.convertDistributor) {
        await this.getProductPrice();
        let _price = this.priceMembership.installments.find(x => x.number == 1);

        this.billingData.push({
          productId: this.priceMembership.id,
          productName: this.priceMembership.name,
          currencyCode: this.priceMembership.currencyCode,
          orderType: this.other.billingOrder.product,
          productValue: _price.price,
          amount: 1,
          productTax: 0,
          productCode: this.priceMembership.code,
          priceLevel: 1,
          imageUrl: `https://cdn1.visiontravel.net/Images/products/VIVE001.png`
        });
      }

      this.billingData.push(
        {
          productId: this.database.products.UPVIVE03,
          productName: `${this.$i18n.t("Upgrade")} - ${product.productName}`,
          currencyCode: "USD",
          orderType: this.other.billingOrder.product,
          productValue: product.price,
          productTax: 0,
          amount: 1,
          productCode: "UPVIVE03",
          priceLevel: 1,
          imageUrl: `https://cdn1.visiontravel.net/Images/products/${product.productCode}.png`
        },
        {
          productId: product.autoshipId,
          productName: product.autoshipName,
          currencyCode: "USD",
          orderType: this.other.billingOrder.product,
          productValue: this.isVivelo ? 0.0 : product.autoshipPrice,
          productTax: 0,
          amount: 1,
          productCode: product.autoshipCode,
          priceLevel: 1,
          imageUrl: `https://cdn1.visiontravel.net/Images/products/${product.productCode}.png`
        }
      );

      await this.getWayToPay();
    },

    async getWayToPay() {
      let data = {
        applicationId: this.database.application.OV,
        countryCode: this.countryCode,
        productId: this.productId,
        supplierId: this.database.supplier.visionTravel,
        language: this.$i18n.locale.toUpperCase(),
        currencyCode: this.billingData[0].currencyCode,
        contactId: this.contactDto.id
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}WayToPay/GetAvailable`,
        data: data,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.wayToPayOption = [];
          } else {
            this.wayToPayOption = result.data.wayToPay;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.merchantOption = [];
        }
      );
    },

    async createPayment() {
      this.$vs.loading();
      let _wayToPayTotal = 0;
      let _productTotal = 0;
      let _otherValue = 0;
      let _taxesValue = 0;
      if (this.billingData.length > 0) {
        if (
          this.billingData.filter(
            x => x.orderType == this.other.billingOrder.wayToPay
          ).length > 0
        ) {
          _wayToPayTotal = this.billingData
            .filter(x => x.orderType == this.other.billingOrder.wayToPay)
            .reduce(
              (sum, current) => sum + parseFloat(current.productValue),
              0
            );
        }

        if (
          this.billingData.filter(
            x => x.orderType == this.other.billingOrder.product
          ).length > 0
        ) {
          _productTotal = this.billingData
            .filter(x => x.orderType == this.other.billingOrder.product)
            .reduce(
              (sum, current) => sum + parseFloat(current.productValue),
              0
            );
        }
        _otherValue = this.productPrice.other;
        _taxesValue = this.productPrice.taxes;
      }

      let _data = {};
      let _payment = {};
      if (this.purchaseId) _data.purchaseId = this.purchaseId;
      else _data.purchaseId = 0;

      _data.language = this.$i18n.locale;
      _data.currencyCode = this.billingData[0].currencyCode;
      _data.countryCode = this.countryCode;
      _data.contactId = this.contactDto.id;
      _data.itaCode = this.contactDto.itaCode;
      _data.applicationId = this.database.application.OV;
      _data.supplierId = this.database.supplier.visionTravel;
      _data.createdBy = this.contactDto.email;
      _data.purchaseTypeId = 4;
      _data.purchaseDetail = [];
      _data.wayToPayId = this.wayToPayOptionId;
      _data.rateValue = 1;
      _data.taxesValue = _taxesValue;
      _data.otherValue = _otherValue;
      _data.installment = 1;

      let productList = this.billingData.filter(
        x => x.orderType == this.other.billingOrder.product
      );
      for (let x = 0; x < productList.length; x++) {
        let value = 0;
        if (productList[x].amount == 0) value = productList[x].productValue / 1;
        else value = productList[x].productValue / productList[x].amount;

        this.products.push(productList[x].productCode);
        this.productsPrice.push(productList[x].productValue);
        _data.purchaseDetail.push({
          productId: productList[x].productId,
          amount: productList[x].amount,
          value: value
        });
      }

      if (this.wayToPayOptionId == this.database.merchantType.creditCard) {
        _payment.creditCardTypeId = this.creditcardPayment.creditCardTypeId;
        _payment.cardNumber = this.creditcardPayment.cardNumber;
        _payment.securityCode = this.creditcardPayment.cardSecurityCode;
        _payment.documentNumber = this.creditcardPayment.documentNumber;
        _payment.name = this.creditcardPayment.cardHolderName;
        _payment.lastName = this.creditcardPayment.cardholderLastName;
        _payment.address = this.creditcardPayment.address;
        _payment.zipCode = this.creditcardPayment.zipCode;
        _payment.email = this.creditcardPayment.email;
        _payment.amount = this.creditcardPayment.amount;
        _payment.currencyCode = this.billingData[0].currencyCode;
        _payment.expirationMonth = this.expirationMonth;
        _payment.expirationYear = this.expirationYear;
        _wayToPayTotal += this.creditcardPayment.amount;
        _data.isPurchase = _productTotal == _wayToPayTotal;
        if (
          this.creditcardPayment.installmentNumber &&
          this.creditcardPayment.installmentNumber != 0
        )
          this.installmentNumber = this.creditcardPayment.installmentNumber;
        else this.installmentNumber = 1;
        _payment.installmentNumber = this.installmentNumber;
      } else if (this.wayToPayOptionId == this.database.merchantType.transfer) {
        _payment.accountId = this.transferPayment.bankAccountId;
        _payment.documentId = this.transferPayment.documentId;
        _payment.referenceCode = this.transferPayment.referenceCode;
        _payment.amount = this.transferPayment.amount;
        _payment.currencyCode = this.billingData[0].currencyCode;
        _payment.language = this.$i18n.locale;
        _wayToPayTotal += this.transferPayment.amount;
        _data.isPurchase = _productTotal == _wayToPayTotal;
      } else if (this.wayToPayOptionId == this.database.merchantType.EWallet) {
        if (this.merchantId == this.database.merchants.Payout) {
          _payment.charge = false;
          _payment.userName = this.eWalletPayment.itaCodeWallet;
        } else if (this.merchantId == this.database.merchants.BlockPay) {
          _payment.charge = true;
          _payment.userName = this.accountWalletId;
          _payment.accountTypeId = this.accountWalletOption.find(
            x => x.id == this.accountWalletId
          ).typeAccountId;
        }

        _payment.WalletId = this.merchantId;
        _payment.amount = this.eWalletPayment.amount;
        _payment.language = this.$i18n.locale;
      }

      _data.payment = _payment;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Membership/BuyProduct`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        async result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            if (!result.data.data.payResult.success) {
              this.$vs.notify({
                time: 6000,
                title: this.$i18n.t("Alert"),
                text: result.data.data.payResult.message,
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.$vs.loading.close();
            } else {
              this.$vs.notify({
                title: this.$i18n.t("Success"),
                text: this.$i18n.t("MsgPaymentMade"),
                color: "success",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
            }
            this.authCode = result.data.data.payResult.allocationNumber;
            await this.sendEmail(result.data.data.purchaseId);

            this.addPay(_data, result.data.data.payResult.success);
            if (this.wayToPayOptionId == this.database.merchantType.transfer) {
              await this.getuploadForm(
                result.data.data.purchaseId,
                result.data.data.paymentId
              );
            }

            await this.getPayments();
            if (this.purchaseId != result.data.data.purchaseId) {
              this.purchaseId = result.data.data.purchaseId;
              this.getEmbedForm(result.data.data.purchaseId);
            }
            this.purchaseId = result.data.data.purchaseId;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }
      );
      this.$vs.loading.close();
    },

    async getuploadForm(purchaseId, paymentId) {
      this.$vs.loading();
      let formData = new FormData();
      formData.set("purchaseId", purchaseId);
      formData.set("paymentId", paymentId);
      formData.set("language", this.$i18n.locale.toUpperCase());
      formData.append(
        "file",
        this.transferPayment.file,
        this.transferPayment.file.Name
      );

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Membership/AddAttachment`,
        data: formData,
        headers: {
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
          "Content-Type": "multipart/form-data"
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("MsgSaved"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    addPay(item, statusPayment) {
      let _merchantType = this.wayToPayOption.filter(
        x => x.wayToPayId == item.wayToPayId
      );
      this.billingData.push({
        productId: this.billingData[0].productId,
        productName: _merchantType[0].name,
        currencyCode: this.billingData[0].currencyCode,
        orderType: statusPayment
          ? this.other.billingOrder.wayToPay
          : this.other.billingOrder.notValidPayment,
        productValue: item.payment.amount,
        productCode: this.billingData[0].productCode
      });
    },

    async getYearsCrediCard() {
      var currentDate = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/");
      this.currentYear = parseInt(currentDate.split("/")[0]);
      this.currentMonth = parseInt(currentDate.split("/")[1]);

      this.expirationYearOption = [];
      this.expirationMonthOption = [];
      var iYear = 0;
      for (let item = this.currentYear; iYear <= 20; item++) {
        iYear++;
        this.expirationYearOption.push(item);
      }

      for (let item = this.currentMonth; item <= 12; item++) {
        this.expirationMonthOption.push(item);
      }
    },

    async expirationYearChange(year) {
      var yearSelect = year;
      this.expirationMonthOption = [];

      if (this.currentYear === yearSelect) {
        for (let item = this.currentMonth; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      } else {
        for (let item = 1; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      }
    },

    setAccountNumber(item) {
      let _account = this.bankType
        .filter(x => x.id == item)
        .map(x => x.accounts);
      this.accountNumberType = _account[0].map(x => x);
    },

    onFileSelected(event) {
      this.transferPayment.file = event.target.files[0];
    },

    setWayToPay(item) {
      let _merchantType = this.wayToPayOption.filter(x => x.wayToPayId == item);

      if (item == 1)
        this.walletOption = _merchantType[0].options[0].wallets.map(x => x);
      else if (item == 2)
        this.bankType = _merchantType[0].options[0].banks.map(x => x);
      else if (item == 3)
        this.creditcardOption = _merchantType[0].options.map(x => x);
    },

    setAccountWallet(item) {
      let _accounts = this.walletOption.find(x => x.walletId == item).accounts;
      this.accountWalletOption = _accounts;
    },

    setInstallmentNumber(item) {
      let _creditcard = this.creditcardOption.find(x => x.cardId == item);
      this.maxCvv = _creditcard.maxCvv;
      this.installmentNumberOption = _creditcard.installmentNumber;
    },

    setContact() {
      this.contactDto = JSON.parse(localStorage.getItem("userInfo"));
    },

    async validate() {
      this.$vs.loading();
      let _totalPay = 0;
      let _payments = 0;
      let products = this.billingData.filter(
        x => x.orderType == this.other.billingOrder.product
      );

      for (let index = 0; index < products.length; index++) {
        _totalPay += products[index].productValue + products[index].productTax;
      }

      let _wayToPayTotal = this.billingData.filter(
        x => x.orderType == this.other.billingOrder.wayToPay
      );

      if (_wayToPayTotal.length > 0) {
        _payments = _wayToPayTotal.reduce(
          (sum, current) => sum + parseFloat(current.productValue),
          0
        );
      }

      if (this.paymentConfirmed >= _totalPay) {
        await this.generate();
      } else if (_payments >= _totalPay) {
        await this.changeStage(this.status.genealogy.verify);
      }

      this.$vs.loading.close();
    },

    async generate() {
      this.$vs.loading();
      let _detail = [];

      let products = this.billingData.filter(
        x => x.orderType == this.other.billingOrder.product
      );

      for (let x = 0; x < products.length; x++) {
        let value = 0;
        if (products[x].amount == 0) value = products[x].productValue / 1;
        else value = products[x].productValue / products[x].amount;

        _detail.push({
          productCode: products[x].productCode,
          amount: products[x].amount,
          value: value,
          priceLevel: products[x].priceLevel
        });
      }

      let _data = {
        enroller: this.itaCode,
        contactType: this.contactDto.contactType,
        name: this.contactDto.name,
        lastName: this.contactDto.lastName,
        address: this.contactDto.address,
        countryName: this.contactDto.countryOperationNameEntry,
        stateName: this.contactDto.stateName,
        cityName: this.contactDto.cityName,
        phone: this.contactDto.phone,
        zipCode: this.contactDto.zipCode,
        countryCode: this.contactDto.countryOperationEntry,
        purchaseId: this.purchaseId,
        payment: [{ transactionNumber: this.paymentList[0].transactionNumber }],
        detail: _detail,
        createdBy: this.contactDto.email
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}upgrade/generate`,
        data: _data,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data.success) {
            this.orderNumber = result.data.orderNumber;
            this.popup = true;
            localStorage.setItem("status", 1);
            let user = JSON.parse(localStorage.getItem("userInfo"));
            user.renewalDate = result.data.renewalDate;
            localStorage.setItem("userInfo", JSON.stringify(user));
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgPurchaseVerify"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
          this.$vs.loading.close();
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async changeStage(newStageId) {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}Tracking/createByPurchase`,
        data: {
          identifier: this.purchaseId,
          entityId: this.other.entity.purchase,
          previousStageId: this.status.genealogy.pending,
          newStageId: newStageId,
          createdBy: this.contactDto.email
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("loggedIn")}`
        }
      }).then(
        async result => {
          if (result.data.status == 200 || result.data.affectedRows == 1) {
            await this.getById();
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    acceptDialog() {
      this.$router.push({ path: "/purchases/index" });
    },

    validPayment() {
      this.$validator.validateAll().then(result => {
        if (
          this.wayToPayOptionId == this.database.merchantType.transfer &&
          (this.transferPayment.file == null || this.transferPayment.file == "")
        ) {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgNotAttachment"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        } else if (!result) {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        } else {
          this.createPayment();
        }
      });
    },

    async sendEmail(purchaseId) {
      let emails = ["a.gomez@visiontravel.net", "v.hernandez@visiontravelmail.net"];
      emails.push(this.email);

      let _data = {
        subject: `${this.$i18n.t("Upgrade")} ${this.itaCode}`,
        message: `<p>Se ha realizado un pago de; ${this.$i18n.t(
          "Upgrade"
        )}. Su ID de compra: ${purchaseId}.</p><p>Para ver el detalle de su compra le recomendamos; iniciar sesión en su Oficina Virtual y luego hacer clic <a href='https://ov.visiontravel.net/purchases/upgrade/${purchaseId}'><strong>AQUÍ</strong></a>.</p><p style='font-style: italic'>NOTA: por cada pago que usted realice sobre su compra, independiente de que éste sea rechazado, aprobado o se encuentre pendiente, le llegará un correo electrónico igual a este.</p>`,
        language: this.$i18n.locale.toUpperCase(),
        createdBy: this.email,
        productType: 2,
        identifier: purchaseId,
        addressesList: emails,
        entityId: this.other.entity.purchase
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Emails/Template`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tokenVT")}`
        }
      });
    },

    async getPayments() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}payment/getByPurchase/${this.purchaseId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data != null) {
            this.paymentList = result.data.data;
            let _total = 0;
            this.paymentConfirmed = _total;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async paymentEwallet(itaCode, referenceId) {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}payout/validStatus/${itaCode}/${referenceId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            await this.getById();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    openDocument(url) {
      window.open(url, this.$i18n.t("Transfer"));
    },

    openResponseMerchant(text) {
      this.responseMerchant = text;
      this.popupResMer = true;
    }
  },
  async created() {
    this.$vs.loading();
    this.setContact();

    if (
      this.$route.params.purchaseId != undefined &&
      this.$route.params.purchaseId != null &&
      this.$route.params.purchaseId != 0
    ) {
      this.isView = true;
      this.purchaseId = this.$route.params.purchaseId;
      //this.itaCode = this.$route.params.itaCode;
      await this.getById();
    } else {
      await this.getUpgrade();
    }

    await this.getYearsCrediCard();
    //await this.getEmbedForm(1715);
    this.$vs.loading.close();
  }
};
</script>
<style lang="scss">
.x-hellosign-embedded {
  z-index: 53000;
}
</style>
